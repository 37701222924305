import React from 'react'

export default function Music() {
    return(
        <div className="Music">
            <h2 className="music-heading">Music</h2>
                <div className="music-container">
                    <iframe 
                        title="The Final Generation of Man"
                        className="music-player" 
                        src="https://bandcamp.com/EmbeddedPlayer/album=3813013107/size=large/bgcol=ffffff/linkcol=0687f5/transparent=true/" 
                        seamless>
                            <a 
                                rel="noreferrer"
                                target="_blank"
                                href="https://deathknellofficial.bandcamp.com/album/the-final-generation-of-man">
                                The Final Generation of Man by Death Knell
                            </a>
                    </iframe>
                </div>
        </div>
    )
}