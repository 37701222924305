import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBandcamp, faItunesNote, faSpotify, faAmazon } from '@fortawesome/free-brands-svg-icons'


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function MusicLinks() {
  const classes = useStyles();


  
  return (
      <div className="MusicLinks">
        <div className={classes.root}>
          <h2 className="links-heading">&nbsp;Hear us on your favorite platform</h2>
            <div className="music-links-container">
             <a target="_blank"
                className="music-links"
                rel="noreferrer"
                href="https://deathknellofficial.bandcamp.com/">
              <Fab aria-label="Bandcamp">
                <FontAwesomeIcon icon={faBandcamp} className="bandcamp" />
              </Fab>
              </a>
              <a target="_blank"
                 className="music-links"
                 rel="noreferrer"
                 href="https://music.apple.com/us/artist/death-knell/1474729930">
              <Fab aria-label="iTunes">
                <FontAwesomeIcon icon={faItunesNote} className="itunes" />
              </Fab>
              </a>
              <a target="_blank"
                 className="music-links"
                 rel="noreferrer"
                 href="https://open.spotify.com/artist/4jkqM7je1NXiqMFoLy8OPz?si=j_QheuL-TpmV7VeNtVj4tw">
              <Fab aria-label="Spotify">
                <FontAwesomeIcon icon={faSpotify} className="spotify"/>
              </Fab>
              </a>
              <a target="_blank"
                 className="music-links"
                 rel="noreferrer"
                 href="https://music.amazon.com/artists/B07VZPY7DL/death-knell?marketplaceId=ATVPDKIKX0DER&musicTerritory=US">
              <Fab aria-label="Amazon Music">
                <FontAwesomeIcon icon={faAmazon} className="amazon"/>
              </Fab>
              </a>
      </div>
    </div>
    </div>
  );
}