import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '2rem',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },

}));

const links = {
  textDecoration: 'none',
  color: 'inherit',
}

export default function Buttons() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <a
        style={links}
        target="_blank"
        rel="noreferrer"
        href="https://deathknellofficial.bandcamp.com/album/the-final-generation-of-man">
      <Fab variant="extended">
        <CloudDownloadIcon />
        &nbsp;&nbsp;Download
      </Fab>
      </a>
    </div>
  );
}