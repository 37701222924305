import React from 'react'
import './App.css';
import PrimarySearchAppBar from './components/PrimarySearchAppBar';
import Hero from './components/Hero'
import About from './components/About'
import Video from './components/Video'
import Music from './components/Music'
import MusicLinks from './components/MusicLinks'
import Footer from './components/Footer'
import VideoMobile from './components/VideoMobile'

function App() {
  return (
    <div className="App">
      <PrimarySearchAppBar />
      <Hero />
      <About />
      {window.innerWidth > 776 ? <Video /> : <VideoMobile />}
      
      <Music />
      <MusicLinks />
      <Footer />
    </div>
  );
}

export default App;
