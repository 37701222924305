import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    maxHeight: 800,
    overflow: 'scroll',
    background: 'transparent',
  },
  bullet: {
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function VideoMobile() {
  const classes = useStyles();
  return (
    <div className="VideoMobile">
        <h2 className="video-heading">Videos</h2>
        <div className="video-card">
      <Card className={classes.root}>
        <CardContent>
            <Typography variant="body2" component="p">
            <iframe 
                title="Boom Click"
                className="video"
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/cfNCAZulT1E" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen></iframe>
            <iframe 
                title="Nashville"
                className="video"
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/E9-QUfyKkA0" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen></iframe>
            <iframe 
                title="Soup"
                className="video"
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/xh7bgAjKi_w" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen></iframe>
            <iframe 
                title="Take You to Meet the Lord"
                className="video"
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/FHejKxNbfSE" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen></iframe>
             <iframe 
                title="Inevitable Decline"
                className="video"
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/3Em_gEOORO8" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen></iframe>
            
            <iframe 
                title="COVID Love Song"
                className="video"
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/FnC0wI5kOkU" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen></iframe>   
            
            </Typography>
        </CardContent>
    </Card>
    </div>
    </div>
  );
}
