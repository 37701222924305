import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth: 850,
    height: 'auto',
    background: 'transparent',
  },
  bullet: {
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function About() {
  const classes = useStyles();
  const about = {
      color: 'white',
  }
  return (
    <div className="About">
        <h2 className="about-header">About</h2>
        <div className="card">
      <Card className={classes.root}>
        <CardContent>
            <Typography style={about} variant="h5" component="h2">
                 Death Knell is fucking angry
            </Typography>
            <Typography variant="body2" component="p">
                
            <p>Their style exemplifies feelings of civil unrest in a way that is uniquely American: forming the blood of political activists, artists, musicians, and any citizen left dissatisfied or silenced by the governing elite into a mirror in which your own reflection may be seen - the chimera of your change; the solemn knell of the United States.</p>
            <p>Their intent is to wake you up. Join us.</p>
            <p>They write “Dark Country” music: Pissed-off Americana that can exist, now, in a country where children are locked in cages, federal goons kidnap protesters off the street, right-wing politicians retweet and repeat Neo-Nazi propaganda on national television, and where the cries to stop killing black people are met with white silence and violent retaliation by the police. They write this music for the disenfranchised, whatever their political leanings, that suffer under the wild notion that People are People and should be treated fairly and with dignity; they write it for those who no longer have voices, silenced by cycles of violence and systemic oppression; they write for their own catharsis; they write because an impeached criminal should not be the President of the United States, nor should an alleged rapist sit on the Supreme Court. Death Knell was created in response to this. And they are fucking angry.</p>
            </Typography>
        </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
    </div>
    </div>
  );
}