import React from 'react';
import Buttons from './Button'

export default function Hero() {
    return(
        <div className='Hero'>
            <h1 className="title">Death Knell</h1>
            <p className="album-title">The Final Generation of Man</p>
            <p className="desc">Debut Full Length album Available now</p>
            <Buttons className="button" />
        </div>
    )
}
