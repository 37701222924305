import React from 'react'

export default function Video() {
    return(
        <div className="Video">
            <h2 className="video-heading">Videos</h2>
            <div className="container">
            <div className="slider">
            <iframe 
                title="Boom Click"
                className="video" 
                src="https://www.youtube.com/embed/cfNCAZulT1E" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen></iframe>
            <iframe 
                title="Heave" 
                className="video"
                src="https://www.youtube.com/embed/EeZM5blhX08" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen></iframe>
            <iframe 
                title="Soup"
                className="video"
                src="https://www.youtube.com/embed/xh7bgAjKi_w" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen></iframe>
            <iframe 
                title="Take You to Meet the Lord"
                className="video"
                src="https://www.youtube.com/embed/FHejKxNbfSE" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen></iframe>
            <iframe 
                title="When the Smoke Clears"
                className="video"
                src="https://www.youtube.com/embed/ez8_P-llFDc"  
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen></iframe>
            <iframe 
                title="O Kavanaugh"
                className="video"
                src="https://www.youtube.com/embed/ddgaCXm8cZI"  
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen></iframe>
            <iframe 
                title="Nashville"
                className="video"
                src="https://www.youtube.com/embed/E9-QUfyKkA0" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen></iframe>
             <iframe 
                title="Inevitable Decline"
                className="video" 
                src="https://www.youtube.com/embed/3Em_gEOORO8" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen></iframe>
            <iframe 
                title="COVID Love Song"
                className="video"
                src="https://www.youtube.com/embed/FnC0wI5kOkU" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen></iframe>
                </div>
            </div>
        </div>
    )
}